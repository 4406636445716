import React, { useEffect, useState } from 'react'; 
import { decodeEntities } from '@wordpress/html-entities';

document.addEventListener('DOMContentLoaded', function () {
    const settings = window.wc.wcSettings.getSetting('pay360_data');

    // Content Component for custom fields and card details
    const Content = (props) => {
        const { eventRegistration, emitResponse } = props;
        const { onPaymentSetup } = eventRegistration;

        // State to hold card details
        const [cardDetails, setCardDetails] = useState({
            cardNumber: '',
            cardExpiryMonth: '',
            cardExpiryYear: '',
            cardCvc: '',
            cardName: '',
        });

        const [error, setError] = useState(null);

        // Dynamic months and years for expiry date
        const currentYear = new Date().getFullYear();
        const months = Array.from({ length: 12 }, (_, i) => i + 1).map(month => String(month).padStart(2, '0'));
        // const years = Array.from({ length: 10 }, (_, i) => currentYear + i);
        const years = Array.from({ length: 10 }, (_, i) => (currentYear + i).toString().slice(-2));
        
        // Handle card details input change
        const handleCardInputChange = (e) => {
            const { name, value } = e.target;
            setCardDetails((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        };

        const validateForm = () => {
            const { cardNumber, cardExpiryMonth, cardExpiryYear, cardCvc, cardName } = cardDetails;
            const isCardNumberValid = /^[0-9]{16}$/.test(cardNumber);
            const isExpiryValid = /^[0-9]{2}$/.test(cardExpiryMonth) && /^[0-9]{2}$/.test(cardExpiryYear);
            const isCvcValid = /^[0-9]{3,4}$/.test(cardCvc);
            
            if (!isCardNumberValid || !isExpiryValid || !isCvcValid || !cardName) {
                setError("Please provide valid card details.");
                return false;
            }
            setError(null);
            return true;
        };

        useEffect(() => {
            const unsubscribe = onPaymentSetup(async () => {
                if (validateForm()) {
                    const { cardNumber, cardExpiryMonth, cardExpiryYear, cardCvc, cardName } = cardDetails;

                    return {
                        type: emitResponse.responseTypes.SUCCESS,
                        meta: {
                            paymentMethodData: {
                                card_no: cardNumber,
                                card_exp_month: cardExpiryMonth,
                                card_exp_year: cardExpiryYear,
                                card_cvc: cardCvc,
                                card_name: cardName,
                            },
                        },
                    };
                }

                return {
                    type: emitResponse.responseTypes.ERROR,
                    message: error,
                };
            });

            return () => {
                unsubscribe();
            };
        }, [cardDetails, error, emitResponse.responseTypes.ERROR, emitResponse.responseTypes.SUCCESS, onPaymentSetup]);

        return (
            <div>
                {decodeEntities(settings.description || '')}
                {/* Card input fields */}
                <div>
                    <img src={settings.card_img} /> 
                </div>
                <div>
                    <input
                        type="text"
                        name="cardNumber"
                        value={cardDetails.cardNumber}
                        onChange={handleCardInputChange}
                        placeholder="Card Number"
                        required
                    />
                    <input
                        type="text"
                        name="cardName"
                        value={cardDetails.cardName}
                        onChange={handleCardInputChange}
                        placeholder="Cardholder Name"
                        required
                    />
                    <div style={{ marginBottom : '10px' }}>
                        <select
                            name="cardExpiryMonth"
                            value={cardDetails.cardExpiryMonth}
                            onChange={handleCardInputChange}
                            style={{ marginRight : '10px' }}
                            required
                        >
                            <option value="">Expiry Month</option>
                            {months.map(month => (
                                <option key={month} value={month}>{month}</option>
                            ))}
                        </select>
                        <select
                            name="cardExpiryYear"
                            value={cardDetails.cardExpiryYear}
                            onChange={handleCardInputChange}
                            style={{ marginRight : '10px' }}
                            required
                        >
                            <option value="">Expiry Year</option>
                            {years.map(year => (
                                <option key={year} value={year}>{year}</option>
                            ))}
                        </select>
                    </div>
                    <input
                        type="text"
                        name="cardCvc"
                        value={cardDetails.cardCvc}
                        onChange={handleCardInputChange}
                        placeholder="CVC"
                        required
                    />
                </div>
                {/* Display error message */}
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </div>
        );
    };
const Icon = () => {
    return settings.icon 
        ? <img src={settings.icon} style={{ marginLeft : 'auto' }} /> 
        : ''
}
// Define the Label component that incorporates the Icon
const LabelWithIcon = (props) => {
    const { PaymentMethodLabel } = props.components;

    return (
        <div style={{ display: 'contents' }}>
           <strong> <PaymentMethodLabel text={decodeEntities(settings?.title || 'pay360')} /></strong>
                {/* Render the icon next to the label */}
            <Icon />
        </div>
    );
};

// Define the pay360PaymentMethod object
const pay360PaymentMethod = {
    name: 'pay360',
    label: <LabelWithIcon />, // Use LabelWithIcon to include the icon
    content: <Content />,
    edit: <Content />,  // Use the same Content component for the editing view
    canMakePayment: () => true,
    ariaLabel: decodeEntities(settings?.title || 'pay360'),
    supports: {
        features: settings?.supports ?? [],
    },
};

    // Register payment method in WooCommerce Blocks
    if (window.wc && window.wc.wcBlocksRegistry) {
        window.wc.wcBlocksRegistry.registerPaymentMethod(pay360PaymentMethod);
    }
});
